<template>
    <div>
        <v-container class="pub mx-auto">
            <div class="titles">
                <p class="tl pt-1">المشاريع الثقافية</p>
            </div>

            <template v-if="loading">
                <v-layout row style="justify-content: center;" v-if="loading">
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                    <v-flex xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                </v-layout>
            </template>
            <template v-else v-for="item in data">
                <v-card class="mb-6 hidden-sm-and-down1 desktop" outlined elevation="3" rounded :key="item">
                    <v-list-item three-line>
                        
                        <v-list-item-avatar rounded tile style="height: auto; width:auto" max-width="300" max-height="200" color="grey" >
                            <v-img rounded :src="'https://alkafeel.net/misc/fikria/thumbnails/tn_'+JSON.parse(item.images)[0]" ></v-img>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <router-link :to="'/fikria/'+item.id" style="text-decoration: none;">
                                <v-list-item-title class="mb-1 t1 card_title"> {{item.title}} </v-list-item-title>
                            </router-link>
                            <v-list-item-subtitle class="tx card_det">{{item.text}}</v-list-item-subtitle>
                            <v-layout row pt-6>
                                <v-flex sm12 md4 >
                                </v-flex>
                                <v-flex sm12 md8>
                                    <v-card-actions class="padding" :style="$vuetify.rtl ? 'justify-content: left' : 'justify-content: right'">
                                        <v-btn :to="'/fikria/'+item.id" class="black--text action_btn mx-3 px-8 py-3"> التفاصيل </v-btn>
                                    </v-card-actions>
                                </v-flex>
                            </v-layout>
                        </v-list-item-content>

                    </v-list-item>
                </v-card>

                <v-card class="mb-6 mx-2 hidden-md-and-up1 mobile" outlined elevation="3" rounded :key="item">
                    <v-img rounded class="mx-auto mt-3 px-3" max-height="400" max-width="450" :src="'https://alkafeel.net/misc/fikria/thumbnails/tn_'+JSON.parse(item.images)[0]" ></v-img>
                    <v-list-item>
                        
                        <v-list-item-content class="text-center">
                            <router-link :to="'/fikria/'+item.id" style="text-decoration: none;">
                                <v-list-item-title class="mb-1 t1 card_title_m"> {{item.title}} </v-list-item-title>
                            </router-link>
                            <p class="tx card_det_m" style="-webkit-line-clamp: 4;-webkit-box-orient: vertical;display: -webkit-box;overflow: hidden;">{{item.text}}</p>
                            
                                    <v-layout row my-6>
                                        <v-flex xs12>
                                            <v-btn width="90%" :to="'/fikria/'+item.id" class="black--text action_btn mx-3 px-8 py-3"> التفاصيل </v-btn>
                                        </v-flex>
                                        <v-flex xs12 mt-2>
                                        </v-flex>
                                    </v-layout>
                                    
                        </v-list-item-content>

                    </v-list-item>
                </v-card>
            </template>
            

        </v-container>
    </div>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            title: 'المشاريع الثقافية',
            data: [],
            loading: true,
            attrs: {
                class: 'ma-3',
                boilerplate: false,
                elevation: 2,
            },
        }
    },
    created() {
        document.title =this.title; 
        this.ini();
    },
    methods: {
        ini () {
            this.$axios.get('fikria/get')
            .then (d => {
                this.loading = false;
                this.data = d.data;
                // console.log(d.data);
            })
        }
    }
}
</script>

<style scoped>
.pub {
  width: 100%;
  padding-top: 30px;
  max-width: 1460px;
}
.pub .titles {
    text-align: center;
}
.pub .titles p {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  font-family: 'cairo_bold';
}
.card_title {
    font-size: 20px;
    font-weight: 700;
    color:#000
}
.card_det {
    font-size: 20px;
    font-weight: 700;
    line-height: 37px;
}
.card_det_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}
.card_title_m {
    font-size: 18px;
    font-weight: 600;
    color:#000
}
.padding {
    padding: 10px 20px
}
.action_btn {
    background: #FFF!important;
    border: solid 2px #B1BD52;
    font-weight: 18px!important;
    font-weight: 700;
}
.mobile {
    display: none;
}
@media (max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}
</style>