<template>
    <div>
        <v-container class="pub mx-auto">
            <div class="titles">
                <router-link style="text-decoration: none;" to="/fikria_projects"><p class="tl pt-1">المشاريع الثقافية</p></router-link>
            </div>

            <template v-if="loading">
                <v-layout row style="justify-content: center;" v-if="loading">
                    <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                    <v-skeleton-loader  width="800" min-width="800" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences paragraph, sentences paragraph, sentences"></v-skeleton-loader>
                </v-layout>
            </template>
            <template v-else v-for="item in data">
                <div class="news_cont pb-6" :style="$vuetify.theme.dark ? 'background-color: #1b1b1b;' : 'background-color: #EEE;'">
                    <p class="tl main">{{item.title}}</p>
                    <div style="white-space: pre-wrap;" class="tx text" v-html="item.text"></div>

                    <v-layout row mt-6>
                        <v-flex sm6 xs6 lg3 v-for="i in JSON.parse(item.images)" :key="i">
                            <a data-fancybox="gallery" :data-caption="i.title" :href="'https://alkafeel.net/misc/fikria/' + i" class="">
                                <v-img aspect-ratio="1" class="ma-6" :src="'https://alkafeel.net/misc/fikria/thumbnails/tn_' + i"></v-img>
                            </a>
                        </v-flex>
                    </v-layout>
                </div>
                
            </template>
            

        </v-container>
    </div>
</template>

<script>
// import axios from "axios";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
export default {
    data() {
        return {
            title: 'المشاريع الثقافية',
            data: null,
            loading: true,
            attrs: {
                class: 'ma-3',
                boilerplate: false,
                elevation: 2,
            },
        }
    },
    created() {
        document.title =this.title; 
        this.ini();
        this.iniFancy();
    },
    methods: {
        ini () {
            this.$axios.get('fikria/show/'+this.$route.params.id)
            .then (d => {
                this.loading = false;
                this.data = d.data;
                // console.log(d.data);
            })
        },
        iniFancy () {
            Fancybox.bind('[data-fancybox="gallery"]', {
                dragToClose: false,
                Toolbar: false,
                closeButton: "top",
                Image: {
                zoom: false,
                },
                on: {
                    initCarousel: (fancybox) => {
                        // console.log(fancybox);
                        const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
                        fancybox.$container.style.setProperty("--bg-image",`url("${slide.src}")`);
                    },
                    "Carousel.change": (fancybox, carousel, to, from) => {
                    const slide = carousel.slides[to];
                    fancybox.$container.style.setProperty("--bg-image", `url("${slide.src}")`);
                    },
                },
            });
        },
    }
}
</script>

<style scoped>
.pub {
  width: 100%;
  padding-top: 30px;
  max-width: 1260px;
}
.pub .titles {
    text-align: center;
}
.pub .titles p {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  font-family: 'cairo_bold';
}
.main {
    font-size: 26px;
}
.text {
    font-size: 24px;
}
.news_cont {
    padding: 10px;
    border-radius: 5px;
}
.card_title {
    font-size: 20px;
    font-weight: 700;
    color:#000
}
.card_det {
    font-size: 20px;
    font-weight: 700;
    line-height: 37px;
}
.card_det_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}
.card_title_m {
    font-size: 18px;
    font-weight: 600;
    color:#000
}
.padding {
    padding: 10px 20px
}
.action_btn {
    background: #FFF!important;
    border: solid 2px #B1BD52;
    font-weight: 18px!important;
    font-weight: 700;
}
.mobile {
    display: none;
}
@media (max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}
</style>